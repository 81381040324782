<template>
  <div class="bg-primary pt-5">
    <div class="container">
      <h4 class="text-white mb-5">{{ t("team.name") }}</h4>
      <div class="d-flex align-items-start">
        <a-select
          :placeholder="t('team.team_type')"
          size="large"
          class="me-3"
          ref="select"
          style="width: 200px"
          :options="
            store.state.currentLanguage === 'th' ? optionsTH : optionsEN
          "
          @change="handleFilterCategory"
          allow-clear
        >
          <template #option="{ label: label }">
            {{ label }}
          </template>
        </a-select>
        <sat-search-input
          class="mb-5"
          :placeHolder="t('team.team_name')"
          @search="handleSearch"
          advanceSearch="team"
        />
      </div>
      <!-- <div class="teams-filter-wrapper mb-3">
        <a-card :bordered="false">
          <div class="d-flex align-items-center">
            <h4 class="me-5 mb-0">สโมสร</h4>
            <div class="logos overflow-hidden w-100 d-flex align-items-center">
              <span v-for="(team, idx) in allTeams" :key="`t-${idx}`" class="me-5">
                <img :src="team?.logo ?? require('@/assets/logo-placeholder.svg')" height="50" />
              </span>
            </div>
          </div>
        </a-card>
      </div> -->
      <a-card :bordered="false">
        <!-- {{allTeam}} -->
        <div>
          <div :class="{ 'd-none': searching || (filtered && filtered !== 1) }">
            <h4 class="mb-4">{{ t("team.thai_team") }}</h4>
            <div v-if="loading" class="loading-container" style="height: 200px">
              <LoadingOutlined />
            </div>
            <slide-team
              v-else-if="teams?.length"
              :slideToShow="helpers.checkWindowSlide()"
              :totalItem="teams?.length"
              :onFetchData="onFetchTeam"
              objectName="teams"
              global_config_local_types="1"
              class="mb-5"
            >
              <template v-for="data in teams" :key="data.id">
                <team-card :data="data" />
              </template>
            </slide-team>
            <!-- <sat-card-carousel type="team" :list="teams" :max="5" class="mb-5" />
            <sat-carousel v-if="teams.length" :slideToShow="5" :totalItem="teams.length" class="mb-5">
              <template v-for="data in teams" :key="data.id">
                <team-card :data="data" />
              </template>
            </sat-carousel> -->
            <a-empty v-else :description="false" />
          </div>
          <div :class="{ 'd-none': searching || (filtered && filtered !== 2) }">
            <h4 class="mb-4">{{ t("team.international_team") }}</h4>

            <!-- <sat-carousel
              v-if="foreignTeams?.length"
              :slideToShow="5"
              :totalItem="foreignTeams?.length"
              class="mb-5"
            >
              <template v-for="data in foreignTeams" :key="data?.id">
                <team-card :data="data" />
              </template>
            </sat-carousel> -->
            <div v-if="loading" class="loading-container" style="height: 200px">
              <LoadingOutlined />
            </div>
            <slide-team
              v-else-if="foreignTeams?.length"
              :slideToShow="helpers.checkWindowSlide()"
              :totalItem="foreignTeams?.length"
              :onFetchData="onFetchTeam"
              objectName="foreignTeams"
              global_config_local_types="2"
              class="mb-5"
            >
              <template v-for="data in foreignTeams" :key="data.id">
                <team-card :data="data" />
              </template>
            </slide-team>
            <a-empty v-else :description="false" />
          </div>
          <div :class="{ 'd-none': searching || (filtered && filtered !== 3) }">
            <h4 class="mb-4">{{ t("team.thai_club") }}</h4>
            <!-- <sat-carousel
              v-if="localClubTeams?.length"
              :slideToShow="5"
              :totalItem="localClubTeams?.length"
              class="mb-5"
            >
              <template v-for="data in localClubTeams" :key="data.id">
                <team-card :data="data" class="" />
              </template>
            </sat-carousel> -->
            <div v-if="loading" class="loading-container" style="height: 200px">
              <LoadingOutlined />
            </div>
            <slide-team
              v-else-if="localClubTeams?.length"
              :slideToShow="helpers.checkWindowSlide()"
              :totalItem="localClubTeams?.length"
              :onFetchData="onFetchTeam"
              objectName="localClubTeams"
              global_config_local_types="3"
              class="mb-5"
            >
              <template v-for="data in localClubTeams" :key="data.id">
                <team-card :data="data" />
              </template>
            </slide-team>
            <a-empty v-else :description="false" />
          </div>
          <div :class="{ 'd-none': searching || (filtered && filtered !== 4) }">
            <h4 class="mb-4">{{ t("team.international_club") }}</h4>
            <!-- <sat-carousel
              v-if="foreignClubTeams?.length"
              :slideToShow="5"
              :totalItem="foreignClubTeams.length"
              class="mb-5"
            >
              <template v-for="data in foreignClubTeams" :key="data?.id">
                <team-card :data="data" class="" />
              </template>
            </sat-carousel> -->
            <div v-if="loading" class="loading-container" style="height: 200px">
              <LoadingOutlined />
            </div>
            <slide-team
              v-else-if="foreignClubTeams?.length"
              :slideToShow="helpers.checkWindowSlide()"
              :totalItem="foreignClubTeams?.length"
              :onFetchData="onFetchTeam"
              objectName="foreignClubTeams"
              global_config_local_types="4"
              class="mb-5"
            >
              <template v-for="data in foreignClubTeams" :key="data.id">
                <team-card :data="data" />
              </template>
            </slide-team>
            <a-empty v-else :description="false" />
          </div>
          <div :class="{ 'd-none': !searching }">
            <h4 class="mb-4">
              {{
                filtered === 0
                  ? "ค้นหาทีม/สโมสรทั้งหมด"
                  : filtered === 1
                  ? "ทีมชาติไทย"
                  : filtered === 2
                  ? "ทีมชาติ คู่แข่ง"
                  : filtered === 3
                  ? "สโมสร ไทย"
                  : filtered === 4
                  ? "สโมสร คู่แข่ง"
                  : ""
              }}
            </h4>
            <!-- <div
              v-if="loadingSearch"
              class="loading-container"
              style="height: 200px"
            >
              <LoadingOutlined />
            </div> -->
            <a-skeleton v-if="loadingSearch" class="py-5" active />
            <!-- <slide-team
              v-else-if="result?.length"
              :slideToShow="5"
              :totalItem="result?.length"
              :onFetchData="onFetchTeam"
              objectName="result"
              global_config_local_types="0"
              class="mb-5"
            >
              <template v-for="data in result" :key="data.id">
                <team-card :data="data" />
              </template>
            </slide-team> -->
            <div v-else-if="result.length > 0">
              <div class="row" :class="helpers.checkWindowRowCol()">
                <div v-for="data in result" :key="data.id" class="col mb-4">
                  <team-card :data="data" type="athletes" />
                </div>
              </div>
              <p
                class="text-center"
                v-if="searching && result.length >= sizeSearch * lastPageSearch"
              >
                <a-button
                  v-if="!loadingMore"
                  type="link"
                  size="large"
                  @click="onLoadMoreData"
                >
                  <sat-icon name="caretDown" class="fs-2"></sat-icon>
                </a-button>
                <LoadingOutlined v-else class="fs-2" />
              </p>
            </div>
            <a-empty v-else :description="false" />
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  watch,
  ref,
  reactive,
  toRef,
  toRefs,
  onUpdated,
  Ref,
  onRenderTracked,
} from "vue";
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatCarousel from "@/components/carousel/SatCarousel.vue";
import TeamCard from "@/components/carousel/TeamCard.vue";
import useTeamRepositories from "@/_composables/useTeamRepositories";
import { SelectTypes } from "ant-design-vue/lib/select";
import SlideTeam from "@/components/team/slideTeam.vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { teamService } from "@/_services";
import SatIcon from "@/components/SatIcon.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Helpers } from "@/_modules/helpers";

export default defineComponent({
  components: {
    SatSearchInput,
    SatCarousel,
    TeamCard,
    SlideTeam,
    LoadingOutlined,
    SatIcon,
  },
  setup() {
    const { t } = useI18n({
      useScope: "global",
    });
    const helpers = Helpers;

    const store = useStore();
    const state = reactive({
      teams: [],
      foreignTeams: [],
      localClubTeams: [],
      foreignClubTeams: [],
      filtered: 0,
      loading: true,
      searching: false,
      result: [],
      loadingSearch: false,
      search: "",
      lastPageSearch: 1,
      sizeSearch: 5,
      query: {},
      loadingMore: false,
    });

    const { run: getTeamRepositories } = useRequest(teamService.list, {
      manual: true,
      onSuccess: (formattedData) => {
        // console.log("getTeamRepositories :: ", formattedData);
      },
    });

    const { run: getTeamsOverview } = useRequest(teamService.getTeamsOverview, {
      manual: true,
      onSuccess: (formattedData) => {},
    });

    onMounted(async () => {
      try {
        helpers.checkWindowSlide();
        await onFetchTeam({
          page: 1,
          size: helpers.checkWindowSlide(),
          global_config_local_types: 1,
          objectName: "teams",
        });
        await onFetchTeam({
          page: 1,
          size: helpers.checkWindowSlide(),
          global_config_local_types: 2,
          objectName: "foreignTeams",
        });
        await onFetchTeam({
          page: 1,
          size: helpers.checkWindowSlide(),
          global_config_local_types: 3,
          objectName: "localClubTeams",
        });
        await onFetchTeam({
          page: 1,
          size: helpers.checkWindowSlide(),
          global_config_local_types: 4,
          objectName: "foreignClubTeams",
        });
      } catch (error) {
        // console.log("Teams catch :: ", error);
      } finally {
        state.loading = false;
      }
    });

    // const onFetchAllTeams = () => {
    //   const { repositories } = useTeamRepositories(ref(state.query.all));
    // };

    const onFetchTeam = async (params) => {
      const { objectName, ...other } = params;
      const { teams } = await getTeamRepositories({
        ...state.search,
        ...other,
      });
      state[`${objectName}`] = [...state[`${objectName}`], ...teams.data];
      return teams;
    };

    const handleSearch = async (search) => {
      state.loadingSearch = true;
      state.search = search;
      state.query = search;
      const { type, ...other } = search;
      if (search && search?.name) {
        if (type !== "filter") {
          state.searching = true;
        } else {
          state.searching = false;
        }
        const res =
          (
            await getTeamsOverview({
              size: state.sizeSearch,
              page: state.lastPageSearch,
              global_config_local_types: state.filtered,
              ...other,
            })
          ).teams ?? [];
        state.result = res.data;
      } else {
        state.searching = false;
      }
      state.loadingSearch = false;
    };

    const onLoadMoreData = async () => {
      state.loadingMore = true;
      const res =
        (
          await getTeamsOverview({
            size: state.sizeSearch,
            page: state.lastPageSearch + 1,
            global_config_local_types: state.filtered,
            ...state.query,
          })
        ).teams ?? [];
      state.result = [...state.result, ...res.data];
      state.lastPageSearch += 1;
      state.loadingMore = false;
    };

    const optionsTH = ref([
      {
        value: "1",
        label: "ทีมชาติไทย",
      },
      {
        value: "2",
        label: "ทีมชาติคู่แข่ง",
      },
      {
        value: "3",
        label: "สโมสรไทย",
      },
      {
        value: "4",
        label: "สโมสรคู่แข่ง",
      },
    ]);

    const optionsEN = ref([
      {
        value: "1",
        label: "Thai National Team",
      },
      {
        value: "2",
        label: "International Team",
      },
      {
        value: "3",
        label: "Thai Club",
      },
      {
        value: "4",
        label: "International Club",
      },
    ]);

    const handleFilterCategory = (value) => {
      state.filtered = Number(value || 0);
      if (!value) {
        handleSearch({ type: "search", ...state.search });
      } else {
        handleSearch({ type: "filter", ...state.search });
      }
      // if (state.searching) {
      //   handleSearch({ type: "filter", ...state.search });
      // }
    };
    return {
      t,
      store,
      helpers,
      optionsTH,
      optionsEN,
      handleFilterCategory,
      ...toRefs(state),
      onFetchTeam,
      handleSearch,
      onLoadMoreData,
    };
  },
  //   mounted() {
  //       const tagA = document.querySelectorAll('a');
  //       console.log('mounted teams', tagA);
  //       tagA.forEach(ele => {
  //           ele.addEventListener('click', ()=>{
  //               console.log('click event', ele );
  //           })
  //       })
  //   }
});
</script>
<style scoped lang="less">
.position-wrapper {
  .rounded-circle {
    width: 40px;
    height: 40px;
    line-height: 1.6em;
  }
}
</style>
